<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <dato-form
          :id="routeParams.id"
          :idtabla="routeParams.idtabla"
          :validar-requeridos="routeQuery.validar_requeridos"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import DatoForm from './components/DatoForm'
import Data from './DatoEditData'
import { getTablaById } from '@/utils/consts'

export default {
  components: {
    DatoForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      tablaPrincipal: {},
    }
  },
  async created () {
    // routeQuery.validar_requeridos: (1) para controlar valores requeridos únicamente cuando se está aceptando el presupuesto
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.tablaPrincipal = getTablaById(parseInt(this.routeParams.idtabla))
      this.title = `Modificar datos de ${this.tablaPrincipal.titleSingular}`
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.update(this, this.formData, this.routeParams.id)
      this.$appRouter.go(-1)
    },
  },
}
</script>
